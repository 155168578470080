<template>
  <div id="PrintArea" class="checkoutOrder-print tw-bg-main">
    <!-- top -->
    <div class="p-md-3 p-1 mb-3 row noPrint">
      <p
        class="fw-bolder col-4 p-0 tw-text-size20"
        style="line-height: 30px; text-decoration: underline"
      >
        結單列印頁面
      </p>
      <div class="col-8 p-0 text-end">
        <!-- 設定 -->
        <div class="fw-bolder tw-text-size18 d-inline-block me-2">
          <div class="d-inline-block me-2">
            <input id="Img" type="checkbox" v-model="option.img" />
            <label for="Img">顯示商品圖片</label>
          </div>
          <div class="d-inline-block">
            <input id="SubTotal" type="checkbox" v-model="option.subTotal" />
            <label for="SubTotal">顯示小計</label>
          </div>
        </div>
        <!-- A4 -->
        <div class="dropdown d-inline-block me-2">
          <button
            class="tw-btn tw-btn-secondary dropdown-toggle"
            type="button"
            id="A4Menu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            A4
          </button>
          <ul class="dropdown-menu">
            <!-- <li class="pointer">
              <a class="dropdown-item" @click="print('a4', 'normal')"
                >正常列印</a
              >
            </li> -->
            <li class="pointer">
              <a class="dropdown-item" @click="print('a4', 'compact')"
                >緊湊列印</a
              >
            </li>
          </ul>
        </div>
        <!-- 標籤紙 -->
        <div class="dropdown d-inline-block me-2">
          <button
            class="tw-btn tw-btn-secondary dropdown-toggle"
            type="button"
            id="Lable"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            標籤紙
          </button>
          <ul class="dropdown-menu">
            <!-- <li class="pointer">
              <a class="dropdown-item" @click="print('lable', 'normal')"
                >正常列印</a
              >
            </li> -->
            <li class="pointer">
              <a class="dropdown-item" @click="print('lable', 'compact')"
                >緊湊列印</a
              >
            </li>
          </ul>
        </div>
        <button class="tw-btn tw-btn-secondary me-2" @click="switchMode">
          切換結單模式
        </button>
      </div>
    </div>
    <!-- content -->
    <div class="tw-container" v-if="printData">
      <!-- 收件資訊 -->
      <div class="fw-bolder" v-if="printData.buyerInfo">
        <p class="mb-2">收件姓名: {{ printData.buyerInfo.buyerName }}</p>
        <p class="mb-2">收件電話: {{ printData.buyerInfo.buyerPhone }}</p>
        <p>帳單備註: {{ printData.buyerInfo.billNote }}</p>
      </div>
      <hr class="m-0 my-2" />
      <!-- 結單表格 -->
      <div class="table-responsive">
        <table class="table table-hover mb-2" v-if="printData.merchandises">
          <thead>
            <tr>
              <th width="50" v-if="option.img">圖片</th>
              <th style="min-width: 150px">商品</th>
              <th style="min-width: 150px">款式</th>
              <th width="100">結單數</th>
              <th width="100">單價</th>
              <th width="100" v-if="option.subTotal">小計</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="merchandise in printData.merchandises"
              :key="merchandise[0]"
            >
              <tr v-if="merchandise[1].totalCheckoutQuantity > 0">
                <td v-if="option.img">
                  <Image
                    class="table-img"
                    :imageHash="merchandise[1].frontPicture"
                    size="s"
                    v-if="merchandise[1].frontPicture"
                    :alt="merchandise[1].frontPicture"
                    style="width: 50px; height: 50px"
                  ></Image>
                  <img
                    src="@/assets/other-images/noImg.png"
                    v-else
                    style="width: 50px; height: 50px"
                  />
                </td>
                <td>
                  <p class="text-break">
                    {{ merchandise[1].merchandiseName }}
                  </p>
                </td>
                <td>
                  <template
                    v-for="style in merchandise[1].styles"
                    :key="style[1]"
                  >
                    <p
                      class="text-break"
                      v-if="style[1].currentCheckOutTotalQty > 0"
                    >
                      {{ style[1].styleName }}
                    </p>
                  </template>
                </td>
                <td>
                  <template
                    v-for="style in merchandise[1].styles"
                    :key="style[1]"
                  >
                    <p v-if="style[1].currentCheckOutTotalQty > 0">
                      {{ style[1].currentCheckOutTotalQty }}
                    </p>
                  </template>
                </td>
                <td>
                  <template
                    v-for="style in merchandise[1].styles"
                    :key="style[1]"
                  >
                    <p v-if="style[1].currentCheckOutTotalQty > 0">
                      {{ style[1].currentPrice }}
                    </p>
                  </template>
                </td>
                <td v-if="option.subTotal">
                  <template
                    v-for="style in merchandise[1].styles"
                    :key="style[1]"
                  >
                    <p>
                      {{
                        $methods.numberToFixed(
                          style[1].currentCheckOutTotalQty *
                            style[1].currentPrice
                        )
                      }}
                    </p>
                  </template>
                </td>
              </tr>
            </template>
            <tr>
              <td
                colspan="5"
                class="fw-bolder"
                v-if="printData.totalCheckoutQuantity"
              >
                總數: {{ printData.totalCheckoutQuantity }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 金額 -->
      <div class="row">
        <!-- 帳單平衡 -->
        <div
          class="p-0"
          :class="{ 'col-6': printData.balances.length > 0 }"
          v-if="printData.balances.length > 0"
        >
          <p class="fw-bolder mb-2">帳單平衡:</p>
          <template
            v-for="(balance, index) in printData.balances"
            :key="balance.id"
          >
            <p class="text-break mb-1">
              {{ index + 1 }}. 原因: {{ balance.reson }}，金額:
              {{ balance.money }}
            </p>
          </template>
        </div>
        <!-- 總金額 -->
        <div
          class="p-0"
          :class="{ 'col-6': printData.balances.length > 0 }"
          v-if="printData.amountInfo"
        >
          <p class="fw-bolder mb-2">金額:</p>
          <p>運費: {{ printData.amountInfo.shippingCost }}</p>
          <p>帳單平衡: {{ printData.amountInfo.balanceTotal }}</p>
          <p>訂單金額: {{ printData.amountInfo.merchOrderTotalPrice }}</p>
          <p v-if="printData.amountInfo.finalStoreDiscount && printData.amountInfo.finalStoreDiscount.discountAmount">
            結單優惠: -{{ printData.amountInfo.finalStoreDiscount.discountAmount }}
          </p>
          <p>購物金折抵: {{ printData.amountInfo.money }}</p>
          <hr class="m-0 my-2" />
          <p class="fw-bolder">
            應付金額: {{ printData.amountInfo.finalTotal }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    printData: {
      type: Object,
      default: {
        merchandises: new Map(),
        buyerInfo: null,
        balances: [],
        amountInfo: null
      }
    },
  },
  data() {
    return {
      option: {
        img: false,
        subTotal: true,
      },
    }
  },
  methods: {
    // 切換模式
    switchMode() {
      this.$emit('notifyFromPrintPage', {
        option: 'switchMode',
        value: 'checkout',
      })
    },
    // 列印
    print(size, status) {
      // 先處理紙張大小
      $('.tw-container').removeClass('a4 lable')
      if (size === 'a4') $('.tw-container').addClass('a4')
      else $('.tw-container').addClass('lable')
      // 再處理列印方式
      // if (status === 'compact') {
      //   $(".tw-container").removeClass("page-break");
      // } else {
      //   $(".tw-container").removeClass("page-break");
      //   $(".tw-container").addClass("page-break");
      // }
      let printArea = document.getElementById("PrintArea");
      print(printArea);
    },
  },
}
</script>